.mc__form-container {
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 15em;
  color: white;
}
.mc__title {
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputField__label {
  display: grid;
  grid-row-gap: 10px;
  color: black;
  font-size: 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.inputField__field {
  background-color: white;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  padding: 16px 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  font-weight: bold;
  color: black;
}

::placeholder {
  /* Firefox */
  font-weight: normal;
  color: black;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  font-weight: normal;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
  font-weight: normal;
}

input[disabled] {
  background-color: black;
  cursor: default;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.45);
}
input:hover,
.inputField__field:hover,
input.inputField__submit:hover {
  background-color: #e0a9d2;
  color: black;
  font-weight: bold;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.45);
  transform: scale(1);
}

input.inputField__submit {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: white;
  color: black;
  width: 10em;
  height: 3em;
  border-radius: 10px;
  margin-top: 2em;
}

/* Estilos para mensajes de información */

.mc__alert {
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: rgb(219, 200, 219);
}
/* 
width: 3em;
height: 3em;
border-radius: 50%;
border: none;
outline: none;
padding: 0;
transition: padding 1s; */
