* {
  margin: 0;
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
body {
  /* background-color: #020030; */
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-header {
  width: 100%;
  height: 100vh;
  background-image: url(../images/01.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: calc(10px + 2vmin);
  color: white;
}
img.logo {
  max-width: 200px;
  text-align: center;
}
.btn.language,
.btn-language-image {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 0;
  transition: padding 1s;
}
.btn-language-image:hover{
  padding: 0.4em;
}
.nav-bar {
  display: flex;
  padding-bottom: 1.5em;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100vw;
  background-color: #020030;
}
ul {
  list-style: none;
}
.nav-bar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin-top: 10px;
}

.nav-bar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 8%;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.676);
  transition: padding 1s;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: padding 1s background-color 1000ms linear;
}

.nav-bar.sticky img {
  width: 100px;
  justify-content: left;
}

.nav-bar.sticky ul {
  width: auto;
}

a {
  text-decoration: none;
  transition: 0.5s;
  color: #b1b0cb;
}

a:hover {
  color: #f9faf8;
  cursor: pointer;
}
.main-text {
  background-color: rgba(0, 0, 0, 0.632);
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 5em;
  transition: padding 1s;
}
.main-text > h1 {
  font-size: 2em;
  transition: font-size 1s;
}
.main-text > h1:hover {
  font-size: 2.5em;
}
h2 {
  font-size: 1em;
}

/* @keyframes section-one {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}
@media (prefers-reduced-motion: no-preference) {
  .section-one {
    animation-name: section-one;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
} */

.section-one {
  display: flex;
  gap: 15em;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #b1b0cb;
  margin-bottom: 5em;
  transition: gap 1s;
}

.section-one.effectScrollCenter {
  gap: 2em;
}

.section-one h3 {
  border: 2px solid white;
  border-radius: 20px;
  width: 20em;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-size: 2em;
  color: white;
  padding-bottom: 2em;
  line-height: 1.8em;
}
img.together-img {
  width: 35em;
  border-radius: 20px;
}

.section-two {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
}
.section-two-wrapper {
  margin: 0 auto;
  width: 90vw;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: black;
  border-radius: 20%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
img.features-img {
  width: 100px;
}

.card-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  border-radius: 20px;
  box-sizing: border-box;
  transition: 1s;
}
.card-feature:hover {
  box-sizing: border-box;
  padding: 20px;
  background-color: #e0a9d2;
  color: #020030;
}
.card-feature-title {
  font-size: 2em;
}
.card-feature-text {
  font-size: 1em;
  inline-size: 10em;
}

.buttom-budget {
  border: 2px solid white;
  padding: 0.5em;
  border-radius: 20px;
  font-size: 0.9em;
  box-shadow: rgb(255, 255, 255) 0px 7px 29px 0px;
}

.scroller-text {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  width: 20em;
}

.scroller-text > span {
  position: absolute;
  top: 0;
  animation: slide 5s infinite;
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}

footer {
  position: fixed;
  background-color: black;
  bottom: 0;
  height: 120px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 2em;
  color: white;
}
footer .menu-footer {
  display: flex;
  justify-content: space-between;
  gap: 2em;
}
footer .change-language-text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.4em;
}
.react-icons {
  vertical-align: middle;
}
.phone {
  margin-top: 0.2em;
}
.whatsapp-icon {
  background-color: green;
  size: 100px;
}
/* CAROUSEL Y CONTAINER DE TEXTO */
.container {
  background-color: white;
  text-align: center;
  padding: 20px;
  font-size: 2em;
}
.wrapper-social-icons {
  display: flex;
  text-align: rigth;
  justify-content: space-around;
  min-width: 20vw;
}
.wrapper-projects {
  margin-top: 10vh;
  padding-bottom: 5em;
  display: flex;
  flex-wrap: wrap;
  gap: 5em;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
}
.wrapper-projects h3 {
  font-weight: bold;
  color: white;
}
.wrapper-projects h2 {
  font-weight: bold;
  font-size: 2rem;
  color: pink;
}
.wrapper-projects .projects-description {
  display: flex;
  flex-direction: column;
  width: 600px;
  align-items: center;
  background-color: rgba(87, 107, 148, 0.523);
  border-radius: 30px;
  max-height: 80vh;
}
.wrapper-projects .projects-description h3 {
  font-size: 2rem;
  margin: 5vh;
  color: white;
}
.wrapper-projects .projects-description p {
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  padding-bottom: 2em;
}
.img-carousel {
  margin: 0 auto;
  width: 600px;
  border-radius: 20px;
}
.carousel {
  border-radius: 55px 55px 55px 55px;
  margin: 0 auto;
  /*   margin-top: 10vh; */
  text-align: center;
  /*   transform: perspective(20cm) rotateY(-15deg); */
  box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.75);
}
@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}

@media (max-width: 1000px) {
  .app-header {
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .main-text {
    margin-top: 0;
  }
  .section-one {
    flex-wrap: wrap;
    font-size: 0.8em;
  }
  .section-one h3 {
    width: 80%;
    margin: 0 auto;
  }
  img.together-img {
    width: 95vw;
    border-radius: 20px;
  }
  .section-two{
    flex-shrink: 0;
  }
  .section-two-wrapper{
    display: flex;
    gap: 8em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .card-feature{
    font-size: 1em;
  }
  .card-feature-text {
    inline-size: 10em;
  }
  img.features-img {
    width: 6em;
  }
  .container {
    margin: 0.5em;
    border-radius: 1em;
  }
  .wrapper-projects {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .wrapper-projects .projects-description {
    display: flex;
    width: 100vw;
    white-space: wrap;
  }
  /*   .wrapper-projects {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .projects-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
  .img-carousel {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    border-radius: 20px;
  }
  .wrapper-projects .projects-description h3 {
    font-size: 0.7em;
  }
  .wrapper-projects .projects-description h2 {
    font-size: 0.6em;
  }
  .wrapper-projects .projects-description p {
    font-size: 0.4em;
    width: 90%;
    margin: 0 auto;
  }
  .wrapper-projects .projects-description {
    border-radius: 20px;
  }

  footer {
    font-size: 1em;
    height: 70px;
    padding-left: 1em;
    padding-right: 0.8em;
  }
  footer.sticky {
    position: fixed;
  }
  .react-icons {
    vertical-align: middle;
  }
  .buttom-budget {
    visibility: hidden;
  }
  .phone {
    margin-top: 0.2em;
  }
  .menu-footer ul {
    margin: 0 auto;
  }
  .nav-bar.stickyBurguer {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    padding: 5px 5%;
    background-color: black;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.676);
    transition: padding 1s;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: padding 1s background-color 1000ms linear;
  }

  /* BURGER MENU FOR MOBILES */

  /* SIZE OF IMAGE */
  img.logo.burger {
    width: 4em;
    text-align: center;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    top: 25px;
    right: 10px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #5a3759;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #4c1752e6;
    padding: 0.5em 0.5em 0;
    font-size: 1.2em;
    letter-spacing: 0.3em;
    height: 3em;
    line-height: 2.4em;
    transition: line-height 1s;
    border-radius: 20px;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #522a54;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
